<template>
	<vue100vh class="full-page max-full-page" :class="{'landingPage': true}">
		<vue-headful title="Welcome"/>
		<div class="tab-content">
			<h1>Welcome</h1>
			<div class="row">
				<div class="grid col-sm-12">
					<div class="inside">
						<div class="search-container">
							<a v-if="!searchKeyword" class="search-box" @click="$modal.show('search-modal')"><icon icon-type="icon-search-big"></icon> <span>Enter Group or SKU #</span></a>
							<a v-if="searchKeyword" class="search-box" @click="$modal.show('search-modal')"><icon icon-type="icon-search-big"></icon> <span>{{searchKeyword}}</span></a>
						</div>
						<div class="top row">
							<div class="visual-col get-inspired col-sm-6 col-xs-12" @click='$navigate("get-inspired");$ua.trackEvent("Landing", "Click", "/get-inspired");'>
								<div class="inner content">
									<img src="~@/assets/landing/GetInspired@2x.jpg" alt="Get Inspired"/>
									<div class="bottom">
										<div class="content">
											<span>Get</span>&nbsp;Inspired
										</div>
									</div>
								</div>
							</div>

							<div class="visual-col your-vision-col col-sm-6 col-xs-12" @click='goToZeroGravity();$ua.trackEvent("Landing", "Click", "/zero-gravity");'>
								<div class="inner content">
									<img src="~@/assets/zero/ZeroGravity_Landing.jpg" alt="Zero Gravity"/>
									<div class="bottom">
										<div class="content">
											<span>See</span> Product Showcase
										</div>
									</div>
								</div>
							</div>


							<!--
							<div class="visual-col your-vision-col col-sm-6 col-xs-12" @click='$navigate("flx");$ua.trackEvent("Landing", "Click", "/flx");'>
								<div class="inner content">
									<img src="~@/assets/landing/FLX_Gateway@2x.jpg" alt="FLX Media Chair"/>
									<div class="bottom">
										<div class="content">
											<span>See</span> Product Showcase
										</div>
									</div>
								</div>
							</div>
							-->

							<div class="visual-col your-vision-col col-sm-6 col-xs-12" @click='$navigate("your-vision");$ua.trackEvent("Landing", "Click", "/your-vision");'>
								<div class="inner content">
									<img src="~@/assets/landing/CreateYourVision@2x.jpg" alt="Your Vision"/>
									<div class="bottom">
										<div class="content">
											<span>Create</span> Your Vision
										</div>
									</div>
								</div>
							</div>
							<div class="visual-col all-products col-sm-6 col-xs-12" @click='$navigate("all-products");$ua.trackEvent("Landing", "Click", "/all-products");'>
								<div class="inner content">
									<img src="~@/assets/landing/BrowseAllProducts.jpg" alt="All Products"/>
									<div class="bottom">
										<div class="content">
											<span>Browse</span> All Products
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<search-modal :searchKeyword="searchKeyword" v-on:update-search="updateSearch"></search-modal>
		<bottom-nav></bottom-nav>
	</vue100vh>
</template>

<script>
	import BottomNav from '../components/BottomNav';
	import SavedButton from '../components/SavedButton';
	import StoreChooser from '../screens/StoreChooser';
	import SearchModal from '../components/SearchModal';
	import Icon from '../components/Icon';
	import vue100vh from 'vue-100vh';
	import _ from 'lodash';

	import vueHeadful from 'vue-headful';
	import { mapGetters, mapActions } from 'vuex';

	export default {
		name: 'landing-page',
		data(){
			return{
				chooserStatus: false,
				searchKeyword: ''
			}
		},
		components: {
			bottomNav: BottomNav,
			searchModal: SearchModal,
			icon: Icon,
			storeChooser: StoreChooser,
			vueHeadful: vueHeadful,
			vue100vh
		},
		computed: {
			...mapGetters({
				fabrics: 'customizableFabrics',
				pillows: 'customizablePillows'
			})
		},
		mounted(){
	 	},
		beforeDestroy() {
  		},
		methods: {
			updateSearch(newSearch){
				this.searchKeyword = newSearch;
			},
			productDetails(sku){
				this.$navigate('/thirty-in-thirty/sku/'+sku);
			},
			goToZeroGravity(){
				this.setSearchKeyword("Zero Gravity");
				this.$navigate({ name: 'all-products-grid', params: {area: 'Search', passedSearchKeyword: "Zero Gravity"}});
			},
			...mapActions([
				'setSearchKeyword',
			])

		}
	}
</script>

